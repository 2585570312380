// import ScrollService from './NativeScroll'
import Component from '../../core/Component'
import ScrollService from './VirtualScroll'

ScrollService.getScrollOffset = ({element}) => {
    if (element && element.classList.contains('PillarCard')) {
        const pillarHeader = document.querySelector('.PillarHeader')
        const pillarHeaderRect = pillarHeader.getBoundingClientRect()
        return pillarHeaderRect.height
    } else {
        const header = document.querySelector('.Header')
        const headerRect = header.getBoundingClientRect()
        return headerRect.top + headerRect.height + 10
    }
}

export const disableScroll = () => ScrollService.disableScroll()
export const enableScroll = () => ScrollService.enableScroll()
export const getScroll = () => ScrollService.getScroll()
export const getScrollTop = () => ScrollService.getScrollTop()
export const scrollToElement = (element, options) => ScrollService.scrollToElement(element, options)

export default ScrollService